<template>
    <div>
        <!---------- Text Section ---------->
        <section class="container px-3 px-sm-0">
            <div class="row">
                <div class="col-12 col-md-9 m-auto">
                    <p>At Ondaq, we believe technology should be enjoyable and time equals money.<br> OnDaQ is a marketplace and an online platform that will provide tools and marketing strategies for independent beauty professionals, salons and, barbers via its web and mobile application, so business owners and professionals can render the best services to their clients.</p>
                </div>
            </div>
        </section>
    </div>
</template>


<script>
    export default {
    }
</script>