<template>
    <MainAppContainer>
        <TermsofService />
        <DownloadApp />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import TermsofService from '@/components/pages/TermsofService.vue'
import DownloadApp from '@/components/common/DownloadApp'
export default {
    components: {
        MainAppContainer,
        TermsofService,
        DownloadApp
    }
}
</script>